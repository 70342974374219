var $class="se2--navigation-backto-top",$name="NavigationBacktoTop",$path="app/components/NavigationBacktoTop/index.js",$this={$class,$name,$path,};import { footerSelector } from 'app/components/FooterGlobalSE/config';

import { css, delays } from 'configs';

import shell from 'app/modules/shell';

import debounce from 'lodash/debounce';

// Number of screens to scroll before showing the button
const buttonOffset = 2;

export const buttonBottomOffset = 20;
let footerHeight = null;
let buttonElement = null;
let footerElement = null;
let intersectionObserver = null;
let resizeObserver = null;

export default shell.registerComponent($this, ({
  addEventListener, configs, mount, publishMessage, unmount,
}) => {
  const getNodes = () => {
    buttonElement = document.querySelector(`.${$class}`);
    footerElement = document.querySelector(footerSelector);
  };

  const onElementClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });

    document.activeElement.blur();
    publishMessage(configs.messages.focusHeader);
  };

  const onDocumentScroll = () => {
    const show = window.pageYOffset <= window.innerHeight * buttonOffset;
    buttonElement.classList.toggle(css.classNames.hide, show);
  };

  const positionButtonOnScroll = () => {
    intersectionObserver = new IntersectionObserver(([{ intersectionRatio }]) => {
      const isNegativeIntersectionRatio = intersectionRatio <= 0;
      buttonElement.style.position = isNegativeIntersectionRatio ? 'fixed' : 'absolute';
      buttonElement.style.insetBlockEnd = isNegativeIntersectionRatio
        ? `${buttonBottomOffset}px` : `${+footerHeight + +buttonBottomOffset}px`;
    });
    intersectionObserver.observe(footerElement);
  };

  const pasteButtonBeforeFooter = () => {
    footerElement.before(buttonElement);
  };

  const calculateMeasurments = () => {
    footerHeight = footerElement?.offsetHeight;
  };

  const setButtonPosition = () => {
    buttonElement.style.insetBlockEnd = buttonElement.style.position === 'absolute'
      ? `${+footerHeight + +buttonBottomOffset}px` : `${buttonBottomOffset}px`;
  };

  const updateMeasurmentsOnResize = () => {
    calculateMeasurments();
    setButtonPosition();
  };

  const onPageScroll = debounce(onDocumentScroll, delays.ms100);

  mount(() => {
    getNodes();
    calculateMeasurments();

    if (footerElement) {
      if (!document.querySelector(`.${$class} + ${footerSelector}`)) {
        pasteButtonBeforeFooter();
      }

      positionButtonOnScroll();

      resizeObserver = new ResizeObserver(updateMeasurmentsOnResize);
      resizeObserver.observe(footerElement);
    }

    addEventListener(buttonElement, 'click', onElementClick);
    addEventListener(document, 'scroll', onPageScroll);
  });

  unmount(() => {
    intersectionObserver?.disconnect();
    resizeObserver?.disconnect();
  });
});
